import { createSlice, createEntityAdapter, EntityId, PayloadAction } from '@reduxjs/toolkit'

export interface UploadFile {
  id: string
  type: string
  file: File
}

const uploadFilesAdapter = createEntityAdapter<UploadFile, EntityId>({
  selectId: (file) => `${file.id}`,
})

export const uploadFilesSlice = createSlice({
  name: 'uploadFile',
  initialState: uploadFilesAdapter.getInitialState(),
  reducers: {
    addFile: (state, action: PayloadAction<File>) => {
      let type = "unknown"
      if (action.payload.type === "text/csv") {
        type = "cspm"
      }
      if (action.payload.name.includes("Runtime_Events_host")) {
        type = "host"
      }
      if (action.payload.name.includes("Runtime_Events_cloud")) {
        type = "cloud"
      }
      if (action.payload.name.includes("Vulnerability_Management_Overview")) {
        type = "vuln"
      }
      uploadFilesAdapter.addOne(state, {
        id: action.payload.name,
        type,
        file: action.payload,
      })
    },
    deleteFile: uploadFilesAdapter.removeOne,
    empty: uploadFilesAdapter.removeAll,
  },
  selectors: {
    selectSlice: state => state,
    selectFilesLength: state => Object.keys(state.entities).length,
    selectByType: (state, type: string) => Object.values(state.entities).filter((v)=>v.type === type)
  }
})

// Action creators are generated for each case reducer function
export const { addFile,deleteFile,empty } = uploadFilesSlice.actions
export const { selectSlice, selectByType, selectFilesLength } = uploadFilesSlice.selectors

export default uploadFilesSlice.reducer
