
type Selector<T> = (item:T)=>string
type Result<T> = {[key: string]: T[]}
export const groupby = <T>(arr: T[], fn: Selector<T>): Result<T> => {
    const result: Result<T> = {}

    for (const item of arr) {
        const key = fn(item)
        if (Object.hasOwn(result, key)) {
            result[key].push(item)
        }
        else {
            result[key] = [item]
        }
    }
    return result

}
