import styled from "@emotion/styled"
import { useCallback } from "react"
import { useAppDispatch } from "../../hooks"
import { addFile } from "../../features/uploadFiles/uploadFilesSlice"
import {useDropzone, DropzoneOptions} from 'react-dropzone'

const Label = styled.label`
    display: block;
    height: 140px;
    width: 100%;
    background-color: silver;
    border: 2px dashed white;
`

//type DropType = Parameters<DropzoneOptions["onDrop"]>
type OnDropType = Exclude<DropzoneOptions["onDrop"], undefined>
export type Props = Omit<DropzoneOptions, "onDrop">
export const Dropbox = (props:Props) => {
    const dispatch = useAppDispatch()

    const onDrop = useCallback<OnDropType>((e)=>{
        for (const file of e ?? []) {
            dispatch(addFile(file))
        }
    }, [dispatch])
    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        ...props,
    }) 

    return <>
        <Label {...getRootProps()}>
            <input {...getInputProps()} />
            <p>files drop here...</p>
        </Label>
    </>
}
export default Dropbox
