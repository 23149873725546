import { TCreatedPdf } from "pdfmake/build/pdfmake"

export const id = (data: any) => `${data.Zone}|${data.Policy}`
export const policy = (data: any) => `${data.Policy}`

export const getArrayBuffer = (pdf: TCreatedPdf): Promise<ArrayBuffer> => new Promise((resolve) => {
    pdf.getBlob((data) => data.arrayBuffer().then((data => resolve(data))))
})

export const sum = (arr: number[]) => arr.reduce((acc, cur) => acc + cur, 0)
