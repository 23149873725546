import type pdfMake from "pdfmake/build/pdfmake"

type CreatePdfInput = Parameters<typeof pdfMake.createPdf>

const pool: {[id: string]: (v: any)=>void} = {}

const worker = new Worker("/lib/pdfmake.worker.js")

worker.onmessage = ((e) => {
    const [id, data] = e.data
    pool[id]?.(data)
})
worker.onerror = ((e) => {
    console.error(e)
})

export const createPdf = (...input: CreatePdfInput): Promise<Buffer> =>
    new Promise((resolve) => {
        const id = `${Math.random()}`
        pool[id] = resolve
        worker.postMessage([id, 'createPdf', JSON.stringify(input)])
    })
