
import { groupby } from "./groupby"
import { TDocumentDefinitions, CustomTableLayout } from "pdfmake/interfaces"
import { chart } from './chart'
import { sum } from "./utils"
import {PdfFormat} from './index'
import { createPdf } from "./worker"

export const generateSummary = async (group: { [key: string]: any[] }): Promise<PdfFormat> => {
    const summary = Object.entries(group).map(([key, value]) => {
        const requirements = Object.entries(groupby(value, (v) => `${v["Requirement"]}`))
        const result = {
            Zone: value[0]["Zone"],
            Policy: value[0]["Policy"],
            a: value.filter(v => v["Control Result"] === "Pass").length,
            b: value.filter(v => v["Control Result"] !== "Pass").length,
            c: sum(value.map(v => Number.parseInt(v["Accepted Resource Count"]))),
            d: sum(value.map(v => Number.parseInt(v["Failing Resource Count"]))),
            e: requirements.filter((([k, v]) => v[0]["Requirement Result"] === "Pass")).length,
            f: requirements.filter((([k, v]) => v[0]["Requirement Result"] !== "Pass")).length,
        }

        return result
    })

    // DD
    const tableLayouts: { [name: string]: CustomTableLayout } = {
        default: {
            vLineColor: '#aaa',
            hLineColor: '#aaa',
        }
    }
    const docDefinition: TDocumentDefinitions = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [20, 20, 20, 20],
        background: {
            canvas: [{
                type: 'rect',
                x: 0, y: 0, h: 595.28, w: 841.89,
                color: "#282c2f",
            }]
        },
        defaultStyle: {
            color: "#ffffff",
        },
        styles: {
            header1: {
                font: "Noto_Sans_JP",
                fontSize: 21,
                color: "#ffffff",
            },
            header2: {
                font: "Noto_Sans_JP",
                fontSize: 18,
                color: "#ffffff",
            },
            row: {
                font: "Noto_Sans_JP"
            }
        },
        content: [
            {
                text: `Summary`,
                style: "header1",
            },
            {
                layout: tableLayouts.default,
                table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                        ["Zone", "Policy", "Passing Score by Requirement", "Passing Score by Control", "Passing Score by Resource"],
                        ...await Promise.all(summary.map(async r => [
                            {
                                style: "row",
                                text: r.Zone,
                            },
                            {
                                style: "row",
                                text: r.Policy,
                            },
                            {
                                columns: [
                                    {
                                        svg: await chart([r.e, r.f]),
                                        height: 50,
                                        width: 50,
                                    },
                                    {
                                        text: `${Math.floor(100 * r.e / (r.e + r.f))} % pass\nPass: ${r.e}\nFail: ${r.f}`
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        svg: await chart([r.a, r.b]),
                                        height: 50,
                                        width: 50,
                                    },
                                    {
                                        text: `${Math.floor(100 * r.a / (r.a + r.b))} % pass\nPass: ${r.a}\nFail: ${r.b}`
                                    }
                                ]
                            },
                            {
                                columns: [
                                    {
                                        svg: await chart([r.c, r.d]),
                                        height: 50,
                                        width: 50,
                                    },
                                    {
                                        text: `${Math.floor(100 * r.c / (r.c + r.d))} % pass\nPass: ${r.c}\nFail: ${r.d}`
                                    }
                                ]
                            },
                        ]))
                    ]
                }
            },
        ]
    }

    const pdf = await createPdf(docDefinition, { tableLayouts: tableLayouts })

    return {
        title: "Summary",
        blob: pdf,
    }
}
