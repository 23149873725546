import { useAppSelector } from './hooks';
import { deleteFile, empty, selectByType, selectFilesLength } from "./features/uploadFiles/uploadFilesSlice"
import { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import Dropbox from './components/dropbox/Dropbox';
import { List, ListItem, ListItemButton, TextField, ListItemText, Button, Box, MenuItem, InputAdornment, Menu, Typography, IconButton } from '@mui/material';
import { selectDisplayFrom, selectDisplayTo, selectOrigin, selectUrlParams, setUrl, setDate, selectBase } from './features/reference/referenceSlice';
import { useDispatch } from 'react-redux';
import { Public as PublicIcon, Delete as DeleteIcon } from "@mui/icons-material"
import { generate as genPdf } from "./utils/generate"
import { DatePicker, DateCalendar } from "@mui/x-date-pickers"

function App() {
  const dispatch = useDispatch()

  const containerEl = useRef<HTMLDivElement | null>(null)
  const anchorEl = useRef(null)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(`https://app.us4.sysdig.com/secure/`)
  useEffect(() => {
    dispatch(setUrl(value))
  }, [dispatch, value])
  const [prefix, setPrefix] = useState('')
  const [policy, setPolicy] = useState(`CIS`)

  const origin = useAppSelector(state => selectOrigin(state))
  const originParam = useAppSelector(state => selectUrlParams(state))
  const base = useAppSelector(state => selectBase(state))
  const displayFrom = useAppSelector(state => selectDisplayFrom(state))
  const displayTo = useAppSelector(state => selectDisplayTo(state))

  const length = useAppSelector(state => selectFilesLength(state))
  const cspm = useAppSelector(state => selectByType(state, 'cspm'))
  const host = useAppSelector(state => selectByType(state, 'host'))
  const cloud = useAppSelector(state => selectByType(state, 'cloud'))
  const vuln = useAppSelector(state => selectByType(state, 'vuln'))
  const unknown = useAppSelector(state => selectByType(state, 'unknown'))

  const generate = useCallback(() => {
    dispatch(empty())
    setPrefix("")
    genPdf({
      cspm,
      host,
      cloud,
      vuln,
      container: containerEl?.current,
      policyRegexp: policy,
    }).then((blob) => {
      const href = URL.createObjectURL(blob)
      const anchor = document.createElement("a")
      anchor.href = href
      anchor.download = `${prefix}-${displayFrom}-${displayTo} Report.pdf`
      anchor.click()
      URL.revokeObjectURL(href)
    })

  }, [dispatch, prefix, setPrefix, cspm, host, cloud, vuln, policy, displayFrom, displayTo, containerEl])

  return (
    <div className="App">
      <Box m={2}>
        <Box display="grid" gridTemplateColumns="auto 1fr">
          <Box>
            <DateCalendar
              value={base}
              onChange={(e) => e && dispatch(setDate(e))}
            />
          </Box>
          <Box>
            <Box m={1} display="flex">
              <Box flexGrow={1}>
                <TextField
                  fullWidth
                  ref={anchorEl}
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  label="URL"
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position='end' onClick={() => setOpen(true)} ><PublicIcon /></InputAdornment>
                    }
                  }}
                ></TextField>
              </Box>
              <Button onClick={generate} variant='contained' color='primary' disabled={!length}>Generate</Button>
            </Box>

            <Menu
              anchorEl={anchorEl?.current}
              open={open}
              onClose={() => setOpen(false)}
            >
              <MenuItem onClick={() => setValue("https://eu1.app.sysdig.com/secure/")}>EU</MenuItem>
              <MenuItem onClick={() => setValue("https://secure.sysdig.com/")}>US East</MenuItem>
              <MenuItem onClick={() => setValue("https://us2.app.sysdig.com/secure/")}>US West</MenuItem>
              <MenuItem onClick={() => setValue("https://app.au1.sysdig.com/secure/")}>AP AU</MenuItem>
              <MenuItem onClick={() => setValue("https://app.in1.sysdig.com/secure/")}>AP IN</MenuItem>
              <MenuItem onClick={() => setValue("https://app.us4.sysdig.com/secure/")}>US West (GCP)</MenuItem>
              <MenuItem onClick={() => setValue("https://app.me2.sysdig.com/secure/")}>ME Central (GCP)</MenuItem>
            </Menu>

            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr" m={1}>
              <TextField fullWidth value={prefix} onChange={(e)=>setPrefix(e.target.value)} label="Prefix" />
              <TextField fullWidth value={displayFrom} label="From" slotProps={{ input: { readOnly: true } }}></TextField>
              <TextField fullWidth value={displayTo} label="To" slotProps={{ input: { readOnly: true } }}></TextField>
              <DatePicker label="Base" value={base} onChange={(e) => e && dispatch(setDate(e))} />
              <TextField fullWidth value={policy} onChange={(e) => setPolicy(e.target.value)} label="Policy RegExp" ></TextField>
            </Box>

            <Box m={1}><Dropbox multiple /></Box>
          </Box>

        </Box>


        <Box display="grid" gridTemplateColumns="1fr auto" >
          <Box display="grid" gridTemplateRows="auto 1fr">
            <span></span>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gridTemplateRows="auto auto">
              <Box border="silver solid 1px" m={1}>
                <Typography>ワークロード脅威検知</Typography>
                <List>
                  {host.map(v => <ListItem key={v.id} 
                    secondaryAction={<IconButton onClick={()=>dispatch(deleteFile(v.id))}><DeleteIcon /></IconButton>}>
                    <ListItemText primary={v.file.name} />
                  </ListItem>)}
                </List>
              </Box>
              <Box border="silver solid 1px" m={1}>
                <Typography>脆弱性管理</Typography>
                <List>
                  {vuln.map(v => <ListItem key={v.id} 
                    secondaryAction={<IconButton onClick={()=>dispatch(deleteFile(v.id))}><DeleteIcon /></IconButton>}>
                    <ListItemText primary={v.file.name} />
                  </ListItem>)}
                </List>
              </Box>
              <Box border="silver solid 1px" m={1}>
                <Typography>クラウド脅威検知</Typography>
                <List>
                  {cloud.map(v => <ListItem key={v.id} 
                    secondaryAction={<IconButton onClick={()=>dispatch(deleteFile(v.id))}><DeleteIcon /></IconButton>}>
                    <ListItemText primary={v.file.name} />
                  </ListItem>)}
                </List>
              </Box>
              <Box border="silver solid 1px" m={1}>
                <Typography>クラウド設定診断</Typography>
                <List>
                  {cspm.map(v => <ListItem key={v.id} 
                    secondaryAction={<IconButton onClick={()=>dispatch(deleteFile(v.id))}><DeleteIcon /></IconButton>}>
                    <ListItemText primary={v.file.name} />
                  </ListItem>)}
                </List>
              </Box>
              <Box gridColumn="3" gridRow="1 / -1" border="silver solid 1px" m={1}>
                <Typography>Unknown files</Typography>
                <List>
                  {unknown.map(v => <ListItem key={v.id}
                    secondaryAction={<IconButton onClick={()=>dispatch(deleteFile(v.id))}><DeleteIcon /></IconButton>}>
                    <ListItemText primary={v.file.name} />
                  </ListItem>)}
                </List>
              </Box>
            </Box>
          </Box>
          <List>
            <ListItemButton component="a" href={`${origin?.origin}/secure/#/dashboard/runtime-events?view=host&${originParam}`} target='_blank' disabled={!origin}>
              <ListItemText primary="ワークロード脅威検知" secondary="Runtime_Events_host.pdf" />
            </ListItemButton>
            <ListItemButton component="a" href={`${origin?.origin}/secure/#/vulnerabilities/overview/all?${originParam}`} target='_blank' disabled={!origin}>
              <ListItemText primary="脆弱性管理" secondary="Vulnerability_Management_Overview.pdf" />
            </ListItemButton>
            <ListItemButton component="a" href={`${origin?.origin}/secure/#/dashboard/runtime-events?view=cloud&${originParam}`} target='_blank' disabled={!origin}>
              <ListItemText primary="クラウド脅威検知" secondary="Runtime_Events_cloud" />
            </ListItemButton>
            <ListItemButton component="a" href={`${origin?.origin}/secure/#/compliance/views/results?${originParam}`} target='_blank' disabled={!origin}>
              <ListItemText primary="クラウド設定診断" secondary="Compliance policy results.csv" />
            </ListItemButton>
          </List>
        </Box>




      </Box>
      <div ref={containerEl}></div>
    </div>
  );
}

export default App;
