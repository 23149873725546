import { TDocumentDefinitions, CustomTableLayout } from "pdfmake/interfaces"
import { PdfFormat } from "./index"
import * as translateJson from "./translate.json"
import { createPdf } from "./worker"
const translate = translateJson as {[key: string]: {"ja": string}}

export interface GeneratePosturePdfInput {
    key: string
    value: any[]
}

export const generatePosturePdf = async (input: GeneratePosturePdfInput): Promise<PdfFormat> => {
    const tableLayouts: { [name: string]: CustomTableLayout } = {
        default: {
            vLineColor: '#aaa',
            hLineColor: '#aaa',
        }
    }
    const docDefinition: TDocumentDefinitions = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [20, 20, 20, 20],
        background: {
            canvas: [{
                type: 'rect',
                x: 0, y: 0, h: 595.28, w: 841.89,
                color: "#282c2f",
            }]
        },
        defaultStyle: {
            fontSize: 12,
            color: "#ffffff",
        },
        styles: {
            header1: {
                font: "Noto_Sans_JP",
                fontSize: 21,
                color: "#ffffff",
            },
            header2: {
                font: "Noto_Sans_JP",
                fontSize: 18,
                color: "#ffffff",
            },
            label: {
                font: "Noto_Sans_JP"
            },
            subtext: {
                fontSize: 8,
                color: "#88aa88"
            }
        },
        content: [
            {
                text: `${input.value[0].Zone}`,
                style: "header1",
            },
            {
                text: `${input.value[0].Policy}`,
                style: "header2",
            },
            {
                layout: tableLayouts.default,
                table: {
                    headerRows: 1,
                    dontBreakRows: true,
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto',],
                    body: [
                        ["Control Name", "Control\n Severity", "Control\n Result", "Failing\n Resource\n Count", "Accepted\n Resource\n Count", "Control\n Platform", "Control\n Type"],
                        ...input.value.map(r => [
                            {
                                text: [
                                    {
                                        style: "label",
                                        text: translate[r["Control Name"]]?.ja ?? r["Control Name"],
                                    },
                                    "\n",
                                    {
                                        style: "subtext",
                                        text: r["Control Name"],
                                    }                                    
                                ]
                            },
                            r["Control Severity"],
                            r["Control Result"],
                            r["Failing Resource Count"],
                            r["Accepted Resource Count"],
                            r["Control Platform"],
                            r["Control Type"],
                        ])
                    ]
                }
            },
        ]
    }

    const pdf = await createPdf(docDefinition, { tableLayouts: tableLayouts })

    return {
        title: input.key,
        blob: pdf,
    }
}
