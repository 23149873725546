import Papa from "papaparse"
import { UploadFile } from "../../features/uploadFiles/uploadFilesSlice";
import { id, policy } from "./utils"
import { groupby } from "./groupby"
import { generateSummary } from "./generateSummary";
import { generatePosturePdf } from "./generatePosturePdf";

export interface GenerateInput {
    container: HTMLDivElement | null
    policyRegexp: string
    files: UploadFile[]
}

export interface PdfFormat {
    title: string
    blob: ArrayBuffer
}
export interface GenerateOutput {
    summary: PdfFormat | null
    files: PdfFormat[]
}

export const generate = async (input: GenerateInput): Promise<GenerateOutput> => {

    // Load
    const result = []
    for (const entity of input.files) {
        const data = Papa.parse(await entity.file.text(), {
            header: true,
            delimiter: ",",
            skipEmptyLines: true,
        })
        console.log(data.data[0])
        result.push(...data.data)
    }

    const filterd = result.filter((v) => policy(v).match(input.policyRegexp))
    const group = groupby<any>(
        filterd.sort((a, b) => id(a).localeCompare(id(b))),
        (item) => id(item)
    )

    const summary = input.container && await generateSummary(group)
    const files = await Promise.all(Object.entries(group).map(([key, value]) => generatePosturePdf({ key, value })))

    return {
        summary,
        files,
    }

}
