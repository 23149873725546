import * as echarts from 'echarts';

type EChartsOption = echarts.EChartsOption;
const option: EChartsOption = {
    series: [
        {
            type: 'gauge',
            center: ['50%', '60%'],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 100,
            itemStyle: {
                color: '#b792e2'
            },
            progress: {
                show: true,
                width: 30
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    width: 30
                }
            },
            axisTick: {
                show: false,
                distance: -45,
                splitNumber: 5,
                lineStyle: {
                    width: 2,
                    color: '#999'
                }
            },
            splitLine: {
                show: false,
                distance: -52,
                length: 14,
                lineStyle: {
                    width: 3,
                    color: '#999'
                }
            },
            axisLabel: {
                show: false,
                distance: -20,
                color: '#999',
                fontSize: 20
            },
            anchor: {
                show: false
            },
            title: {
                show: false
            },
            detail: {
                valueAnimation: false,
                width: '60%',
                lineHeight: 40,
                borderRadius: 8,
                offsetCenter: [0, '-15%'],
                fontSize: 60,
                fontWeight: 'bolder',
                formatter: '{value} %',
                color: '#ffffff'
            },
        },
    ]
}


export const chart = async (data: [number, number]): Promise<string> => {
    const gauge = echarts.init(null, null, {
        renderer: "svg",
        width: 400, 
        height: 400,
        ssr: true,
    });
    gauge.setOption(option)
    gauge.setOption({
        series: [
            {
                data: [
                    {
                        value: Math.floor(100 * data[0] / (data[0] + data[1]))
                    }
                ]
            },
        ]
    })
    const svg = gauge.renderToSVGString()
    console.log(svg)
    gauge.dispose()
    return svg
}
