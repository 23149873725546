import { UploadFile } from "../features/uploadFiles/uploadFilesSlice"
import { PDFDocument } from "pdf-lib"
import { PDFOutline, setOutline } from "./pdf"
import {generate as generatePosture } from "./posture"

export interface GenerateInput {
    cspm: UploadFile[]
    vuln: UploadFile[]
    cloud: UploadFile[]
    host: UploadFile[]
    container: HTMLDivElement | null
    policyRegexp: string
}

const counter = (length: number): number[] => {
    return new Array(length).fill(null).map((_, i) => i)
}

export const generate = async (input: GenerateInput): Promise<Blob> => {

    // Load
    const postureResult = await generatePosture({
        container: input.container,
        policyRegexp: input.policyRegexp,
        files: input.cspm,
    })
    const summary = postureResult.summary
    const posture = postureResult.files

    const pdf = await PDFDocument.create()
    const outlines: PDFOutline[] = [];

    for (const entity of input.host) {
        outlines.push({
            title: "Runtime_Events_host",
            to: pdf.getPageCount(),
        })
        const doc = await PDFDocument.load(await entity.file.arrayBuffer())
        const pages = await pdf.copyPages(doc, counter(doc.getPageCount()))
        for (const page of pages) {
            pdf.addPage(page)
        }
    }

    for (const entity of input.cloud) {
        outlines.push({
            title: "Runtime_Events_cloud",
            to: pdf.getPageCount(),
        })
        const doc = await PDFDocument.load(await entity.file.arrayBuffer())
        const pages = await pdf.copyPages(doc, counter(doc.getPageCount()))
        for (const page of pages) {
            pdf.addPage(page)
        }
    }

    for (const entity of input.vuln) {
        outlines.push({
            title: "Vulnerability_Management_Overview",
            to: pdf.getPageCount(),
        })
        const doc = await PDFDocument.load(await entity.file.arrayBuffer())
        const pages = await pdf.copyPages(doc, counter(doc.getPageCount()))
        for (const page of pages) {
            pdf.addPage(page)
        }
    }

    if (summary && posture.length !== 0) {
        outlines.push({
            title: `${summary.title}`,
            to: pdf.getPageCount(),
        })
        const doc = await PDFDocument.load(summary.blob)
        const pages = await pdf.copyPages(doc, counter(doc.getPageCount()))
        for (const page of pages) {
            pdf.addPage(page)
        }
    }

    for (const file of posture) {
        outlines.push({
            title: `Posture_Management_${file.title}`,
            to: pdf.getPageCount(),
        })
        const doc = await PDFDocument.load(file.blob)
        const pages = await pdf.copyPages(doc, counter(doc.getPageCount()))
        for (const page of pages) {
            pdf.addPage(page)
        }
    }

    await setOutline(pdf, outlines);

    return new Blob([await pdf.save()])
}
