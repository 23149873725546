import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as fns from "date-fns"

export interface Reference {
  url: string
  date: Date
}

const init: Reference = {
  url: "",
  date: new Date(),
}

export const referenceSlice = createSlice({
  name: 'reference',
  initialState: init,
  reducers: {
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload
    },
    setDate: (state, action: PayloadAction<Date>) => {
      state.date = action.payload
    }
  },
  selectors: {
    selectSlice: state => state,
    selectIsEnable: state => {
      try {
        new URL(state.url)
        return true
      }
      catch {
        return false
      }
    },
    selectOrigin: state => {
      try {
        return new URL(state.url)
      }
      catch {
        return null
      }
    },
    selectUrlParams: state => {
      const to = fns.startOfWeek(state.date, { weekStartsOn: 1 })
      const from = fns.subDays(to, 14)
      return `from=${fns.getUnixTime(from)}&to=${fns.getUnixTime(to)}`
    },
    selectBase: state => state.date,
    selectDisplayBase: state => {
      return fns.intlFormat(state.date)
    },
    selectDisplayFrom: state => {
      const to = fns.startOfWeek(state.date, { weekStartsOn: 1 })
      const from = fns.subDays(to, 14)
      return fns.intlFormat(from)
    },
    selectDisplayTo: state => {
      const to = fns.startOfWeek(state.date, { weekStartsOn: 1 })
      return fns.intlFormat(to)
    },
  }
})

// Action creators are generated for each case reducer function
export const { setUrl, setDate } = referenceSlice.actions
export const { selectSlice, selectOrigin, selectIsEnable, selectUrlParams, selectBase, selectDisplayBase, selectDisplayFrom, selectDisplayTo } = referenceSlice.selectors

export default referenceSlice.reducer
